import { useEffect, useRef } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

import Box from "@mui/material/Box";

import { userState } from "../interface/MainInterface";
import * as AuthMain from "../auth/AuthMain";
import Toast from "../utils/Toast";
import Login from "../page/login/Login";
import DreamtourLogin from "../page/login/DreamtourLogin";
import NoticePage from "../page/noticePage/NoticePage";
import SafetyInfoPage from "../page/safetyInfoPage/SafetyInfoPage";
import AiDataPage from "../page/aiDataPage/AiDataPage";
import VisaAiChatPage from "../page/visaAiChatPage/VisaAiChatPage";
import AiMeasurePage from "../page/aiMeasurePage/AiMeasurePage";
import FaqPage from "../page/faqPage/FaqPage";
import MyAskPage from "../page/myAskPage/MyAskPage";
import DashBoardPage from "../page/dashBoardPage/DashBoardPage";
import VisaListPage from "../page/visaListPage/VisaListPage";
import NoticeDetail from "../page/noticePage/NoticeDetail";
import SafetyDetailRead from "../page/safetyInfoPage/SafetyDetailRead";
import AIDetailRead from "../page/aiDataPage/AiDataDetailRead";
import { appConfig } from "../config/Config";
import { saveLocal } from "../utils/localstorage_utils";
import MyAskDetail from "../page/myAskPage/MyAskDetail";
import VisaDetail from "../page/visaListPage/VisaDetail";
import PublicRoute from "../routes/PublicRoute";

import TestAiChatPage from "../page/testAiChatPage/TestAiChatPage";
import TestListPage from "../page/testListPage/TestListPage";
import TestDetail from "../page/testListPage/TestDetail";

interface propsType {
  userState: userState;
  setSelectedMenu: any;
  setLeftUpdate: any;
}

const MainConteiner = (props: propsType) => {
  const location = useLocation();
  const toastRef: any = useRef();
  let navigate = useNavigate();

  useEffect(() => {
    props.setSelectedMenu(`/${location.pathname.split("/")[1]}`);
    const sessionCheck = async () => {
      try {
        if (!(await AuthMain.checkAuthenticated())) {
          moveHome();
        } else {
          props.setSelectedMenu(`/${location.pathname.split("/")[1]}`);
        }
      } catch (error) {
        moveHome();
      }
    };

    const moveHome = () => {
      toastRef.current?.toast("세션이 종료되었습니다.\n로그인 화면으로 이동합니다.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      setTimeout(() => {
        window.location.href = "/signin";
      }, 3000);
    };

    const isSafetyPath = location.pathname.includes('safety');

    if (!isSafetyPath) {
      window.localStorage.setItem(
        appConfig.safetySearchKey01,
        JSON.stringify('')
      );
      window.localStorage.setItem(
        appConfig.safetyPageKey01,
        JSON.stringify(1)
      );
    }

    const isNoticePath = location.pathname.includes('notice');
    if (!isNoticePath) {
      saveLocal(appConfig.noticeSearchKey01, "")
    }
    const isMyaskPath = location.pathname.includes('myask');
    if (!isMyaskPath) {
      saveLocal(appConfig.myaskSearchKey01, "")
      saveLocal(appConfig.myaskPageKey01, 1)
    }
    const isAidataPath = location.pathname.includes('aidata');
    if (!isAidataPath) {
      saveLocal(appConfig.myaskSearchKey01, "")
      saveLocal(appConfig.myaskPageKey01, 1)
    }


  }, [location, props]);

  useEffect(() => {
    if (!props.userState.isAuth) navigate("/login");
  }, []);

  return (
    <Box
      sx={{
        transition: "margin 0.5s",
        height: "calc(100vh - 44px)",
      }}
    >
      <Routes>
        <Route path="/login" element={<PublicRoute />}>
          {(window.location.hostname === appConfig.prodURL) ? (
            <Route path="/login" element={<DreamtourLogin />} />
          ) : (
            <Route path="/login" element={<Login />} />
          )}
        </Route>
        <Route path="/" element={<PublicRoute />}>
          <Route
            path="/"
            element={<VisaAiChatPage userState={props.userState} />}
          />
          <Route
            path="/dashboard"
            element={<DashBoardPage
              userState={props.userState}
              setLeftUpdate={props.setLeftUpdate}
            />}
          />
          <Route
            path="/notice"
            element={<NoticePage userState={props.userState} />}
          />
          <Route
            path="/safetyinfo"
            element={<SafetyInfoPage userState={props.userState} />}
          />
          <Route
            path="/aidata"
            element={<AiDataPage
              userState={props.userState}
              setLeftUpdate={props.setLeftUpdate}
            />}
          />
          <Route
            path="/visalist"
            element={<VisaListPage userState={props.userState} />}
          >
            <Route path=":code" element={<VisaDetail userState={props.userState} />} />
          </Route>
          <Route
            path="/visaaichat"
            element={<VisaAiChatPage userState={props.userState} />}
          />
          <Route
            path="/aimeasure"
            element={<AiMeasurePage userState={props.userState} />}
          />
          <Route path="/faq" element={<FaqPage userState={props.userState} />} />
          <Route
            path="/myask"
            element={<MyAskPage userState={props.userState} />}
          />
          <Route
            path="/testlist"
            element={<TestListPage userState={props.userState} />}
          >
            <Route path=":code" element={<TestDetail userState={props.userState} />} />
          </Route>
          <Route
            path="/testaichat"
            element={<TestAiChatPage userState={props.userState} />}
          />
          <Route path="/noticelistread/:sk" element={<NoticeDetail userState={props.userState} />} />
          <Route path="/safetyNewsListRead/:sk" element={<SafetyDetailRead />} />
          <Route path="/myasklistread/:sk" element={<MyAskDetail userState={props.userState} />} />
          <Route path="/aidataRead/:sk" element={<AIDetailRead />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>
      </Routes>
      <Toast ref={toastRef} />
    </Box>
  );
};

export default MainConteiner;
